import { defaultMessages } from "./defaultMessages"
import { findMessage } from "../../utils/Tools"

const messages: { [key: string]: string } = {
    orgName: "Reed",
    welcome: "Log in to Reed"
}

const reedglobal = (key: string, attr: string[]): string => {
    return findMessage(key, messages, attr) || defaultMessages(key, attr)
}

export default reedglobal
