import React, { Fragment, ChangeEvent, useContext, useEffect, useState, FunctionComponent } from "react"
import { executeRequest, RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"
import { Site } from "../interfaces/Site"
import { InteractionMode } from "../interfaces/InteractionMode"
import LoaderButton from "./widgets/LoaderButton"
import { useHistory } from "react-router"
import routes from "../routes"
import { OperationalModeConfig } from "../interfaces/OperationalModeConfig"
import { AuthContext } from "../providers/AuthProvider"
import { AppContext, FeedbackType } from "../providers/AppProvider"
import Select, { ActionMeta, MultiValue } from "react-select"

interface GetSitesRequest {
    userId: number
}

export interface GetSitesResponse {
    success: boolean
    sites: Site[]
}

interface SetSiteRequest {
    userId: number
    orgId: number
}

export interface SetSiteResponse {
    success: boolean
    apiKey: string
    canUseDebugMode: boolean
}

const SiteSelector: FunctionComponent = () => {
    const { operationalModeConfig, onSetOperationalModeConfig, connectedUser, onLogout, Message } =
        useContext(AuthContext)
    const { setFeedback } = useContext(AppContext)
    const [sites, setSites] = useState<Site[]>([])
    const [selectedSites, setSelectedSites] = useState<Site[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [interactionMode, setInteractionMode] = useState<InteractionMode>(
        operationalModeConfig?.interactionMode || InteractionMode.TOUCH
    )
    const [pictureCountdown, setPictureCountdown] = useState<number>(operationalModeConfig?.pictureCountdown || 3)
    const [resetCountdown, setResetCountdown] = useState<number>(operationalModeConfig?.resetCountdown || 3)
    const [idleRestartSeconds, setIdleRestartSeconds] = useState<number>(operationalModeConfig?.idleRestartSeconds || 5)
    const history = useHistory()

    useEffect(() => {
        if (!connectedUser) {
            console.error(Message("getSitesNoConnectUser"))
            return
        }

        setLoading(true)

        const getSitesRequest: GetSitesRequest = {
            userId: connectedUser.id
        }

        const getSites = async () => {
            try {
                const { success, sites }: GetSitesResponse = await executeRequest({
                    endpoint: endpoints.agent.GET_SITES,
                    withToken: true,
                    requestType: RequestType.POST,
                    params: getSitesRequest
                })
                if (success) {
                    setSites(sites)

                    // use received sites in favor of the ones in the operational mode config (received sites are more up to date)
                    const newSelectedSites: Site[] = []
                    operationalModeConfig?.sites?.forEach(site => {
                        const foundSite = sites.find(s => s.id === site.id)
                        if (foundSite) {
                            newSelectedSites.push(foundSite)
                        }
                    })
                    setSelectedSites(newSelectedSites)
                } else {
                    throw new Error()
                }
            } catch (error: any) {
                setFeedback({
                    type: FeedbackType.ERROR,
                    message: error.message,
                    showLoginPromptOnInvalidCredentials: true
                })
            } finally {
                setLoading(false)
            }
        }
        getSites()
    }, []) // eslint-disable-line

    const onSitesChange = (sites: MultiValue<Site>, action: ActionMeta<Site>) => {
        setSelectedSites(sites.map(site => site))
    }

    const selectSite = async () => {
        if (!connectedUser || !selectedSites.length) {
            console.error(Message("setSiteNoConnectUser"))
            return
        }

        setLoading(true)

        const setSiteRequest: SetSiteRequest = {
            userId: connectedUser.id,
            orgId: connectedUser.tempOrgId
        }
        try {
            const { success, apiKey, canUseDebugMode }: SetSiteResponse = await executeRequest({
                endpoint: endpoints.agent.SELECT_SITE,
                withToken: true,
                requestType: RequestType.POST,
                params: setSiteRequest
            })

            if (success) {
                const operationalModeConfig: OperationalModeConfig = {
                    sites: selectedSites,
                    orgId: connectedUser.tempOrgId,
                    apiKey,
                    interactionMode,
                    pictureCountdown,
                    resetCountdown,
                    idleRestartSeconds,
                    canUseDebugMode
                }
                onSetOperationalModeConfig(operationalModeConfig)
                onLogout()
                history.push(routes.CAPTURE_PHOTO)
            } else {
                throw new Error()
            }
        } catch (error: any) {
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message,
                showLoginPromptOnInvalidCredentials: true
            })
            setLoading(false)
        }
    }

    const isTouch: boolean = interactionMode === InteractionMode.TOUCH
    const isContactless: boolean = interactionMode === InteractionMode.CONTACTLESS

    const renderOptions = (seconds: number[]) => {
        return seconds.map(value => (
            <option key={value} value={value}>
                {value} {Message("seconds")}
            </option>
        ))
    }

    return (
        <div className="whiteBox siteSelector">
            <h2>{Message("chooseSite")}</h2>
            <p>{Message("goIntoWorkerAttendance")}</p>
            <p>{Message("appDescription")}</p>

            {sites.length === 0 && loading && (
                <div className="loadingSites">
                    <div className="loader"></div> {Message("fetchingSites")}
                </div>
            )}
            {sites.length === 0 && !loading && <div className="loadingSites">{Message("noSitesFound")}</div>}

            {sites.length > 0 && (
                <Fragment>
                    <div className="siteSelectorDropdown" data-testid="siteSelectorDropdown">
                        <Select
                            id="selectMultiSite"
                            isMulti
                            name="site"
                            options={sites}
                            value={selectedSites}
                            closeMenuOnSelect={false}
                            onChange={onSitesChange}
                            getOptionLabel={(option: Site) => `${option.orgname ? option.orgname + " / " : ""}${option.name}`}
                            getOptionValue={(option: Site) => option.id + ""}
                            placeholder={Message("siteSelectorDropdownPlaceholder")}
                        />
                    </div>

                    <div className="field radioGroup">
                        <label
                            htmlFor="InteractionMode.TOUCH"
                            className={interactionMode === InteractionMode.TOUCH ? "checked" : ""}
                        >
                            <div className="radioHolder">
                                <input
                                    onChange={() => setInteractionMode(InteractionMode.TOUCH)}
                                    type="radio"
                                    id="InteractionMode.TOUCH"
                                    name="interactionMode"
                                    checked={interactionMode === InteractionMode.TOUCH}
                                    data-testid="InteractionMode.TOUCH"
                                />
                            </div>
                            <div className="radioTextHolder">
                                <div className="radioTitle">{Message("touchInterface")}</div>
                                <div className="radioDetails">{Message("touchInterfaceDetails")}</div>
                                {isTouch && (
                                    <div className="radioOptions">
                                        <div className="radioOption">
                                            <div className="radioOptionText">{Message("idleRestartSeconds")}</div>
                                            <div className="radioOptionValue">
                                                <select
                                                    name="pictureCountdown"
                                                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                                        setIdleRestartSeconds(parseInt(event.target.value))
                                                    }
                                                    value={idleRestartSeconds}
                                                >
                                                    {renderOptions([3, 5, 10, 20, 60])}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </label>
                        <label
                            htmlFor="InteractionMode.CONTACTLESS"
                            className={interactionMode === InteractionMode.CONTACTLESS ? "checked" : ""}
                        >
                            <div className="radioHolder">
                                <input
                                    onChange={() => setInteractionMode(InteractionMode.CONTACTLESS)}
                                    type="radio"
                                    id="InteractionMode.CONTACTLESS"
                                    name="interactionMode"
                                    checked={interactionMode === InteractionMode.CONTACTLESS}
                                    data-testid="InteractionMode.CONTACTLESS"
                                />
                            </div>
                            <div className="radioTextHolder">
                                <div className="radioTitle">{Message("contactlessInterface")}</div>
                                <div className="radioDetails">{Message("contactlessInterfaceDetails")}</div>
                                {isContactless && (
                                    <div className="radioOptions">
                                        <div className="radioOption">
                                            <div className="radioOptionText">{Message("pictureCountdown")}</div>
                                            <div className="radioOptionValue">
                                                <select
                                                    name="pictureCountdown"
                                                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                                        setPictureCountdown(parseInt(event.target.value))
                                                    }
                                                    value={pictureCountdown}
                                                >
                                                    {renderOptions(Array.from(Array(20), (e, i) => i + 1))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="radioOption">
                                            <div className="radioOptionText">{Message("resetCountdown")}</div>
                                            <div className="radioOptionValue">
                                                <select
                                                    name="resetCountdown"
                                                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                                        setResetCountdown(parseInt(event.target.value))
                                                    }
                                                    value={resetCountdown}
                                                >
                                                    {renderOptions(Array.from(Array(20), (e, i) => i + 1))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </label>
                        <label
                            htmlFor="InteractionMode.QR"
                            className={interactionMode === InteractionMode.QR ? "checked" : ""}
                        >
                            <div className="radioHolder">
                                <input
                                    onChange={() => setInteractionMode(InteractionMode.QR)}
                                    type="radio"
                                    id="InteractionMode.QR"
                                    name="interactionMode"
                                    checked={interactionMode === InteractionMode.QR}
                                    data-testid="InteractionMode.QR"
                                />
                            </div>
                            <div className="radioTextHolder">
                                <div className="radioTitle">{Message("qrInterface")}</div>
                                <div className="radioDetails">{Message("qrInterfaceDetails")}</div>
                            </div>
                        </label>
                        <label
                            htmlFor="InteractionMode.TOUCH_QR"
                            className={interactionMode === InteractionMode.TOUCH_QR ? "checked" : ""}
                        >
                            <div className="radioHolder">
                                <input
                                onChange={() => setInteractionMode(InteractionMode.TOUCH_QR)}
                                type="radio"
                                id="InteractionMode.TOUCH_QR"
                                name="interactionMode"
                                checked={interactionMode === InteractionMode.TOUCH_QR}
                                data-testid="InteractionMode.TOUCH_QR"
                                />
                            </div>
                            <div className="radioTextHolder">
                                <div className="radioTitle">{Message("touchQrInterface")}</div>
                                <div className="radioDetails">{Message("touchQrInterfaceDetails")}</div>
                            </div>
                        </label>
                    </div>

                    <div className="field textAlignRight">
                        <LoaderButton onClick={selectSite} disabled={!selectedSites.length} loading={loading}>
                            {loading ? Message("settingSite") : Message("set")}
                        </LoaderButton>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

export default SiteSelector
