import { defaultMessages } from "./defaultMessages"
import { findMessage } from "../../utils/Tools"

const messages: { [key: string]: string } = {
    orgName: "Manpower",
    welcome: "Log in to Manpower"
}

const manpower = (key: string, attr: string[]): string => {
    return findMessage(key, messages, attr) || defaultMessages(key, attr)
}

export default manpower
