import { defaultMessages } from "./defaultMessages"
import { findMessage } from "../../utils/Tools"

const messages: { [key: string]: string } = {
    orgName: "Inspiring Teaching",
    welcome: "Log in to Inspiring Teaching"
}

const inspiringteaching = (key: string, attr: string[]): string => {
    return findMessage(key, messages, attr) || defaultMessages(key, attr)
}

export default inspiringteaching
